/*
 * Want to change this website's styes? Learn more about CSS:
 * https://developer.mozilla.org/en-US/docs/Learn/CSS/Introduction_to_CSS
 */
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body {
    padding: 0;
    margin: 0;
    background: #FEFEFE;
    font-family: 'Roboto', sans-serif;
    min-height: 100vh;
}

h1 {
    font-size: 3.2em;
    color: #FFF;
    text-align: center;
    color: white;
}

h2 {
    text-align: center;
    font-size: 2.5rem;
    color: white;
    letter-spacing: 2px;
    text-decoration: underline;
}

.hero {
    height: 45vh;
    width: 100%;
    background-color: #5543FF;
    display: flex;
    justify-content: center;
    align-items: center;
}

nav {
    width: 100%;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5543FF;
    padding: 5px;
    position: fixed;
    z-index: 2;
    box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.1);
}

nav a {
    margin: 10px;
}

main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    background-color: white;
    padding: 10px;
    position: relative;
    top: -50px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

a {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}

.outer-card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    width: 80%;
}

.card {
    text-align: left;
    position: relative;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 1);
    margin: 75px 0;
    background-color: #5543FF;
    padding-bottom: 60px;
    width: 100%;
}

.card a {
    text-decoration: underline;
}

img {
    margin: 20px;
    width: 900px;
}

p {
    font-size: 1.5rem;
    color: white;
}
